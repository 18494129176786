import React, { useState } from "react";

export const NotesForm = ({addNotes}) => {
    const [value, setValue] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (value) {
            addNotes(value);

            setValue('');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="NotesForm">
            <input type="text" value={value} onChange={(e) => setValue(e.target.value)} className="notes-input" placeholder="Apa Kegiatan Hari Ini?"/>
            <button type="submit" className="notes-btn"> Tambah </button>
        </form>
    )
}