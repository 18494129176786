import React, { useState } from "react";

export const EditNotesForm = ({editNotes, task}) => {
    const [value, setValue] = useState(task.task);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        editNotes(value, task.id);
    };

    return(
        <form onSubmit={handleSubmit} className="NotesForm">
            <input type="text" value={value} onChange={(e) => setValue(e.target.value)} className="notes-input" placeholder="Edit Notes"/>
            <button type="submit" className="notes-btn"> Ubah </button>
        </form>
    )
}