import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export const Notes = ({ task, deleteNotes, editNotes, toogleComplete }) => {
    return(
        <div className="Notes">
            <p className={`${task.completed ? "completed" : "incompleted"}`} onClick={() => toogleComplete(task.id)}> {task.task} </p>
            <div>
                <FontAwesomeIcon className="edit-icon" icon={faPenToSquare} onClick={() => editNotes(task.id)}/>
                <FontAwesomeIcon className="delete-icon" icon={faTrash} onClick={() => deleteNotes(task.id)}/>
            </div>
        </div>
    )
}